import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Booking from '../components/Booking';
import PostSlices from '../components/PostSlices';
import ContactForm from '../components/ContactForm';
import ContactServiceForm from '../components/ContactServiceForm';

const PrismicService = ({ data }) => {
  const { service } = data;
  const {
    title,
    description,
    keywords,
    body,
    image,
    include_contact_form,
  } = service.data;
  return (
    <Layout bodyClass="page-services">
      <SEO title={title.text} pathname={`/services/${service.uid}`} description={description} keywords={keywords} image={service.data.seo_image} />
      <div className="container pt-3 pt-lg-1">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div className="service service-single content-box">
              <h1 className="title">{title.text}</h1>
              { image ? <GatsbyImage alt={image.alt} image={ image.gatsbyImageData } className="rounded m-2" /> : <></> }
              <div className="content" dangerouslySetInnerHTML={{ __html: body.html }}/>
              <PostSlices allSlices={service.data.slices}/>
              <hr />
              { include_contact_form == null || include_contact_form ? 
              <>
                <div className="row">
                  Contact to schedule appointment
                </div> 
                <ContactServiceForm service={service.uid} />
                </>
              : <></> }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    service: prismicServices(id: {eq: $id}) {
      id
      uid
      data {
        include_contact_form
        seo_image {
          thumbnails {
            facebook {
              url
            }
            twitter {
              url
            }
          }
          url
        }
        title {
          text
        }
        body {
          html
        }
        image {
          alt
          gatsbyImageData
          url
        }
        keywords
        description
        slices {
          __typename
          ... on PrismicServicesDataSlicesYoutubevideo {
            id
            slice_type
            primary {
              url {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default PrismicService;
