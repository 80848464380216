import React from "react";
import PropTypes from "prop-types";

class ContactServiceForm extends React.Component {
  
  render() {

    return (
      <form
        data-netlify="true"
        name="service-contact"
        netlify-honeypot="boDo"
        className="pb-4"
        method="POST"
        action="/contact/success"
      >
        <input type="hidden" name="form-name" value="service-contact" />
        <p className="hidden" hidden>
          <label>
            Don’t fill this out if you're human:
            <input name="boDo" />
          </label>
        </p>
        <div className="form-row">
          <div className="col-12 pt-2">
            <label htmlFor="firstName">Name</label>
          </div>
          <div className="col-sm-12 col-md-6 form-group align-bottom">
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div className="col-sm-12 col-md-6 form-group">
            <label htmlFor="lastName" hidden>
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <div className="col-12 form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="col-12 form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="phone"
              className="form-control"
              id="phone"
              aria-describedby="phoneHelp"
              placeholder=""
            />
            <small id="phoneHelp" className="form-text text-muted">
              Please format with `(xxx) xxx - xxxx`
            </small>
          </div>
          <div className="d-none">
            <input type="textbox" name="service" value={this.props.service} />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    );
    
  }
}

ContactServiceForm.defaultProps = {
  form: PropTypes.string.isRequired,
  name: true,
  email: true,
  phone: true,
  service: "",
};

export default ContactServiceForm;
